@media only screen and (max-width: 600px) {
    html {
        font-size: 10px;
        --default-space: 4px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    html {
        font-size: 11px;
        --default-space: 5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    html {
        font-size: 12px;
        --default-space: 6px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
    html {
        font-size: 13px;
        --default-space: 8px;
    }
}

@media only screen and (min-width: 1280px) {
    html {
        font-size: 14px;
        --default-space: 10px;
    }
}

