.top-panel {
    background-color: var(--primary-color);
    border-bottom: var(--primary-border-color) solid 1px;
    display: grid;
    grid-template-columns: clamp(30px, 6svw, 50px) min-content auto min-content;
    gap: 0.2svw;
    padding-right: 1svw;
    .logo {
        background-image: url(../../images/logo192.png);
        background-size: cover;
        background-repeat: no-repeat;
        margin: 5px;
        cursor: pointer;
    }
    .betti {
        font-family: 'betti-font';
        font-size: 2rem;
        color: red;
        align-self: center;
        text-shadow: 1px 1px 2px var(--shadow-color-first), -1px -1px 2px var(--shadow-color-second);
        cursor: pointer;
    }
    .languages {
        align-self: center;
    }
    .mode {
        display: grid;
        align-self: center;
        align-items: center;
        grid-template-columns: min-content 1fr 1fr min-content;
        .mode-icon {
            color: var(--primary-foreground);
            margin: 5px;
            font-size: 1.2rem;
        }
        .mode-left {
            color: var(--primary-foreground);
            background-color: var(--secondary-color);
            border: var(--primary-border-color) outset 1px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 2px 5px;
            font-size: 0.8rem;
        }
        .mode-right {
            color: var(--primary-foreground);
            background-color: var(--secondary-color);
            border: var(--primary-border-color) outset 1px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 2px 5px;
            font-size: 0.8rem;
        }
        
    }

}