.page {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    filter: contrast(0.7);
    background-color: black;
    background-image: url(/images/sport2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .top-panel {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: clamp(30px, 6svw, 50px);
        z-index: 1;
    }
    .page-viewport {
        scroll-behavior: smooth;
        position: absolute;
        left: 0;
        top: clamp(30px, 6svw, 50px);
        right: 0;
        bottom: 0;
        overflow: auto;
        z-index: 0;
    }
    .registration-row {
        position: absolute;
        left: 0;
        top: calc(clamp(30px, 6svw, 50px) - 1px);
        right: 0;
        height: clamp(16px, 3svw, 30px);
        z-index: 2;
        .left-triangle {
            position: absolute;
            width: 0;
            height: 0;
            right: calc(clamp(16px, 3svw, 30px) * 8);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 clamp(16px, 3svw, 30px) clamp(16px, 3svw, 30px) 0;
            border-color: transparent var(--primary-color) transparent transparent;            
        }
        .content {
            position: absolute;
            right: calc(clamp(16px, 3svw, 30px) * 2);
            height: 100%;
            width: calc(clamp(16px, 3svw, 30px) * 6);
            background-color: var(--primary-color);
            display: grid;
            border-bottom: var(--primary-border-color) solid 1px;
            select {
                text-align: center;
                margin: 0;
                background-color: var(--primary-color);
                color: var(--primary-foreground);
                border: none;
                font-size: 0.7rem;
            }
        }
        .right-triangle {
            position: absolute;
            width: 0;
            height: 0;
            right: calc(clamp(16px, 3svw, 30px) + 1px);
            border-style: solid;
            border-width: clamp(16px, 3svw, 30px) clamp(16px, 3svw, 30px) 0 0;
            border-color: var(--primary-color) transparent transparent transparent;
        }
    
    }
}
