@use "Variables.scss";

.grid {
    display: grid;
}

.default-grid-gap {
    gap: 4svw;
}

.small-grid-gap {
    gap: 1svw;
}

.default-padding {
    padding: var(--default-space);
}

.grid-min-100 {
    grid-template-columns: repeat(auto-fit, minmax(min(100px, 100%), 1fr));
}

.grid-min-200 {
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
}

.grid-auto-fit {
    container-type: inline-size;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
}

@container (min-width: 50px) {
    .grid-auto-fit {
        div {
            background-color: green;
        }
    }
}

@container (min-width: 100px) {
    .grid-auto-fit {
        div {
            background-color: pink;
        }
    }
}
