.dark-theme {
    --primary-color: #303030;
    --secondary-color: #909090;
    --footer-color: #000000;
    --alert-color: #f1948a;
    --highlight-color: #7fb3d5;
    --primary-border-color: #505050;
    --secondary-border-color: #b0b0b0;
    --primary-foreground: #EFEFEF;
    --secondary-foreground: #FFFFFF;
    --green-color: #62c593;
    --dark-green-color: #329563;
}

.light-theme {
    --primary-color: #FFFFFF;
    --secondary-color: #CFCFCF;
    --footer-color: #000000;
    --alert-color: #f1948a;
    --highlight-color: #7fb3d5;
    --primary-border-color: #C0C0C0;
    --secondary-border-color: #909090;
    --primary-foreground: #505050;
    --secondary-foreground: #303030;
    --green-color: #62c593;
    --dark-green-color: #329563;
}
