.demo-view {
    --red-color: rgba(200, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    .hint-panel {
        position: absolute;
        width: 60svw;
        left: 20svw;
        top: 0;
        height: 5svh;
        z-index: 1000;
        div:nth-child(1) {
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5svh 5svh 0;
            border-color: transparent var(--red-color) transparent transparent;
        }
        div:nth-child(2) {
            text-align: center;
            position: absolute;
            line-height: 5svh;
            color: white;
            font-size: 1.5rem;
            font-weight: 900;
            left: 5svh;
            top: 0;
            bottom: 0;
            right: 5svh;
            background-color: var(--red-color);
            cursor: pointer;
        }
        div:nth-child(3) {
            position: absolute;
            width: 0;
            height: 0;
            right: 0;
            border-style: solid;
            border-width: 5svh 5svh 0 0;
            border-color: var(--red-color) transparent transparent transparent;
        }
    }
    iframe {
        width: 100%;
        height: 100%;
        z-index: 1;
        border: none;
    }
}