.page {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
}

.page.portal-theme {
    background-color: black;
    background-image: url(/images/earth-night-1918.gif);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.green-button {
    background-color: #43bb5b;
    border: none;
    color: #FFFFFF;
}

.green-button:active {
    background-color: #239b3b;
}

.dark-theme .green-button {
    border-bottom: rgba(0, 0, 0, 0.7) solid 2px;
}

.dark-theme .green-button:active {
    border-top: rgba(0, 0, 0, 0.7) solid 2px;
    border-bottom: none;
}
